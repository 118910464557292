<script>
import {L} from '../js/local.js';
import { getContext } from 'svelte';
const { close } = getContext('simple-modal');
export let msg;
</script>

<div class="popup-form">
    <a href={null} class="close-btn" on:click={close}>
        <span class="icon"></span>
    </a>
    <div class="popup-form__switch-container">
        <div class="popup-form__switch-item is-on">
            <div class="form-container">
                <p class="form-title">{L('MESSAGE')}</p>
                <p class="basefont">{L(msg)}</p>
                <div class="form-group">
                    <a href={null} class="btn btn-submit btn-close" on:click={close}>{L('CLOSE')}</a>
                </div>
            </div>
        </div>
    </div>
</div>

<style>
.btn-close {
  margin-top: 30px;
}
</style>