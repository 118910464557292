<script>
import HeaderContainer from '../components/HeaderContainer.svelte';
import Footer from '../components/Footer.svelte';
import LoginForm from '../components/LoginForm.svelte';
import {L} from '../js/local.js';
import {userdata, levelsStore} from '../js/stores.js';
import { getContext } from 'svelte';
import page from "page";


const { open } = getContext('simple-modal');

export let params;
let logged;

userdata.subscribe(value => logged = (value != null));

let activeNews = [];

$: {
    if (params.itemsCount != activeNews.length) {
        activeNews = [];
        for (let i=0;i<params.itemsCount;i++) {
            activeNews.push(false);
        }
    }
}

let levels={};
levelsStore.subscribe(value => levels = value);

function trimText(text, textLength) {
    if (text.length >= textLength) {
        text = text.substring(0, textLength);
        var lastIndex = text.lastIndexOf(" ");
        text = text.substring(0, lastIndex) + '...';
    }
    return text;
}
</script>

<div class="page-content-group">
    <HeaderContainer pageName="main" />

    <section class="loto-container sale-detail-cards">
        <div class="loto-block">
            <div class="card-panel">
                <div class="content-wrapper">
                    <p class="panel-title">{L(params.panelPrefix + '_HEADER')}</p>
                    <p class="panel-desc">{L(params.panelPrefix + '_DESC_1')}</p>
                    <p class="panel-desc">{L(params.panelPrefix + '_DESC_2')}</p>
                    {#if params.panelPrefix=='VIP_MEMBERSHIP'}
                        <a href={null} on:click={() => {if (logged) page("/profile"); else open(LoginForm, {tab: "login"});}} class="btn btn-get-vip btn-sign-up">{L('GET_VIP_STATUS')}</a>
                    {/if}
                </div>
                <div class="img-wrapper">
                    <img src="images/sale-detail/cards-img.png" alt="cards">
                </div>
            </div>
        </div>
    </section>

    <section class="loto-container news-list-block">
        <div class="loto-block">
            <div class="news-list-wrapper">
                {#each activeNews as active, i}
                <a href={null} class="news-list-item" class:is-on={active} on:click={() => activeNews[i] = !activeNews[i]}>
                    <div class="open-arrow" class:is-on={active}></div>
                    <p class="news-header">{L(params.itemsPrefix +'_HEADER_'+(i+1))}</p>
                    <div class="news-content-wrapper">
                        {#if active}
                        <div class="img-wrapper">
                            {#if params.itemsPrefix=="NEWS"}
                            <img src="images/levels/{levels[i+1]?levels[i+1].pic:'11.png'}" alt="level {i+1}">
                            {:else if params.itemsPrefix=="PROMO"}
                            <img src="images/levels/promo{i+1}.png" alt="promo {i+1}">
                            {/if}
                        </div>
                        <div class="news-text">
                            <p>{@html L(params.itemsPrefix + '_TEXT_'+(i+1)+'_1')}</p>
                            <p>{@html L(params.itemsPrefix + '_TEXT_'+(i+1)+'_2')}</p>
                            <p>{@html L(params.itemsPrefix + '_TEXT_'+(i+1)+'_3')}</p>
                        </div>
                        {:else}
                        <div class="news-text">
                            <p>{@html trimText(L(params.itemsPrefix + '_TEXT_'+(i+1)+'_1'), 200)}</p>
                        </div>
                        {/if}
                    </div>
                </a>
                {/each}
            </div>
        </div>
    </section>

    <Footer />
</div>
