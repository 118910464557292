<script>
import { onMount } from 'svelte';
import {L, lang} from '../js/local.js';
import {userdata, gameStore, producerStore, levelsStore, siteSettings} from '../js/stores.js';
import LoginForm from './LoginForm.svelte';
import PaymentForm from './PaymentForm.svelte';
import SearchForm from './SearchForm.svelte';
import { getContext } from 'svelte';
import page from "page";
import {query} from '../js/scripts.js';

export let pageName, showFavorite, showPopular;
let accountBlockOn=false;
let balances = {};
let anchorElement;
let logo;

const { open } = getContext('simple-modal');
const openLoginForm = (tab) =>
    open(LoginForm, {tab: tab});
const openSearchForm = () =>
    open(SearchForm);

let user;
userdata.subscribe(async(value) => {
    user = value;
    if (user) {
        let formatter = Intl.NumberFormat(undefined, {style:"currency", currency:user.currency});
        balances = {
            total: formatter.format((user.balance+user.balanceBonus)/100),
            common: formatter.format(user.balance/100),
            bonus: formatter.format(user.balanceBonus/100)
        };
        if (user.balanceBonus > 0 ) {
            let wagerData = await query("/server/profile/getWager");
            if (wagerData.error==undefined) {
                balances.wager = formatter.format(wagerData.current/100);
            }
        } else {
            balances.wager = '';
        }
    }
});

let levels={};
levelsStore.subscribe(value => levels = value);
siteSettings.subscribe(value => logo = value.logo)

let root;
onMount(() => {
    window.onscroll = function() {
        let headerHeight = root.clientHeight;
        let scrollPos = this.pageYOffset;
        if ( scrollPos >= (headerHeight/2) ) {
            root.classList.add('header-fix');
        } else {
            root.classList.remove('header-fix');
        }
    }
    anchorElement = document.getElementsByClassName("main-filter-block")[0];
});
</script>

<section class="header-container" bind:this={root}>
    <div class="top-bar-menu">
        <a class="logo img-wrapper" href="/">
            {#if logo}
            <img src="/images/{logo}-{lang}.png" alt="logo" style="max-width: 122px;">
            {/if}
        </a>

        {#if pageName=="main" || pageName=="cabinet"}
        <div class="bottom-group">
            <div class="top-menu-wrapper">
                <div class="top-menu-item">
                    <a href={null} on:click={()=>{
                        page("/slots");
                        if (anchorElement) window.scrollTo({top: anchorElement.offsetTop, behavior: 'smooth'});
                    }}>{L('SLOTS')}</a>
                </div>
                <div class="top-menu-item">
                    <a href={null} on:click={()=>{
                        page("/boardgames");
                        if (anchorElement) window.scrollTo({top: anchorElement.offsetTop, behavior: 'smooth'});
                    }}>{L('BOARDGAMES')}</a>
                </div>
                <div class="top-menu-item">
                    <a href={null} on:click={()=>{
                        page("/minigames");
                        if (anchorElement) window.scrollTo({top: anchorElement.offsetTop, behavior: 'smooth'});
                    }}>{L('MINIGAMES')}</a>
                </div>
                <div class="top-menu-item">
                    <a href={null} on:click={()=>{
                        page("/promotions");
                        window.scrollTo({top: 0});
                    }}>{L('PROMOTIONS')}</a>
                </div>
                <!--
                <div class="top-menu-item">
                    <a href={null}>{L('TOURNAMENTS')}</a>
                </div>
                -->
                <div class="top-menu-item">
                    <a href={null} on:click={()=>{
                        page("/vip_levels");
                        window.scrollTo({top: 0});
                    }}>{L('VIP_LEVELS')}</a>
                </div>
            </div>
            <div class="search-block">
                <a href={null} class="search-icon img-wrapper" on:click={openSearchForm}>
                    <img src="/images/search-icon.svg" alt="search-icon">
                </a>
            </div>
        </div>

        {:else if pageName=="game"}
        <div class="bottom-group">
            <div class="top-menu-wrapper">
                <div class="top-menu-item" class:is-on={showPopular}>
                    <a href={null} on:click={() => {showFavorite=false; showPopular = !showPopular}}>{L('POPULAR')}</a>
                </div>
                {#if user}
                <div class="top-menu-item" class:is-on={showFavorite}>
                    <a href={null} on:click={() => {showPopular=false; showFavorite = !showFavorite}}>{L('FAVORITE')}</a>
                </div>
                {/if}
            </div>
            <div class="search-block">
                <a href={null} class="search-icon img-wrapper" on:click={openSearchForm}>
                    <img src="/images/search-icon.svg" alt="search-icon">
                </a>
            </div>
        </div>
        {/if}

        {#if !user}
        <div class="buttons-wrapper">
            <a href={null} class="btn btn-sign-up" on:click={() => openLoginForm("register")}>{L('SIGN_UP')}</a>
            <a href={null} class="btn btn-sign-in" on:click={() => openLoginForm("login")}>{L('LOG_IN')}</a>
        </div>
        {/if}
        {#if user && pageName!='game'}
        <a href={null} class="account-block" class:is-on={accountBlockOn} on:click={() => accountBlockOn=!accountBlockOn}>
            <div class="account-wrapper">
                <div class="img-wrapper avatar-img">
                    {#if levels}
                    <img src="/images/levels/{levels[user.level]?levels[user.level].pic:'11.png'}" alt="account-img">
                    {/if}
                </div>
                <div class="account-block-content">
                    <div class="account-name">
                        <div class="account-balance-icon img-wrapper">
                            <img src="/images/account-currency-icon.svg" alt="account-balance-icon">
                        </div>
                        <span>{L('BALANCE')}:</span>
                    </div>
                    <div class="account-balance">
                        <div class="account-balance-row">
                            <p>{balances.total}</p>
                            <span class="open-arrow"></span>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="account-block-menu">
                <a href={null} class="btn btn-sign-up" on:click={() => page('/deposit')}>{L('DEPOSIT')}</a>
                <li><a href={null} on:click={()=>page("/profile")}>
                    <p class="account-block-menu__name">{L('COMMON_BALANCE')}</p>
                    <p class="account-block-menu__value">{balances.common}</p>
                </a></li>
                <li><a href={null} on:click={()=>page("/profile")}>
                    <p class="account-block-menu__name">{L('BONUS_BALANCE')}</p>
                    <p class="account-block-menu__value">{balances.bonus}</p>
                </a></li>
                {#if balances.wager}
                <li><a href={null} on:click={()=>page("/profile")}>
                    <p class="account-block-menu__name">{L('WAGER_BALANCE')}</p>
                    <p class="account-block-menu__value">{balances.wager}</p>
                </a></li>
                {/if}
            </ul>
        </a>
        {/if}
    </div>
</section>
