<script>
import {L, lang} from '../js/local.js';
import {siteSettings} from '../js/stores.js';

let logo;
siteSettings.subscribe(value => logo = value.logo)
</script>

<section class="loto-container footer-block">
    <div class="loto-block">
        <div class="description-block">
            <div class="img-wrapper description-logo">
                {#if logo}
                <img src="/images/{logo}-{lang}.png" alt="logo">
                {/if}
            </div>
            <p>{L('ALL_RIGHTS_RESERVED')}</p>
        </div>
    </div>
</section>
