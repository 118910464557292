<script>
import { onMount } from 'svelte';
import {L} from '../js/local.js';

let sliderItem, buttonNext, buttonPrev;
let banners = [];
let lang = 'ru';
let cookieLang = document.cookie.split('; ').find(row => row.startsWith('lang='));
if (cookieLang) lang = cookieLang.split('=')[1];
let alreadyWon = "";

onMount(async() => {
    let response = await fetch("/server/alreadyWon");
    let totalWin = await response.json();
    alreadyWon = Intl.NumberFormat('ru-RU', {style: "currency", currency: "RUB", maximumFractionDigits: 0}).format(totalWin);
   
    response = await fetch("/server/getBanners");
    let resp = await response.json();
    await (async() => banners = resp)();
    let sliderInstance = new Swiper(sliderItem, {
        speed: 400,
        spaceBetween: 0,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev
        },
        simulateTouch: false,
        allowTouchMove: false,
    });
});
</script>

<section class="loto-container main-page-slider">
    <div class="loto-block">
        <div class="swiper-container main-slider" bind:this={sliderItem}>
            <div class="swiper-wrapper">
            {#each banners as banner}
                <div class="swiper-slide">
                    <div class="slide-content" style="background-image: url('/images/banners/{banner.image}')">
                        <p class="slide-title">{@html banner.local[lang].header}</p>
                        <p class="slide-subtitle">{@html banner.local[lang].text}</p>
                    </div>
                </div>
            {/each}
            </div>
            <div class="swiper-button-prev" bind:this={buttonPrev}></div>
            <div class="swiper-button-next" bind:this={buttonNext}></div>
        </div>
        <div class="win-score">
            <p class="win-score-text">{L('ALREADY_WON')}:</p>
            <p class="win-score-value">{alreadyWon}</p>
        </div>
    </div>
</section>
