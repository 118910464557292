export let lang = 'ru';
let cookieLang = document.cookie.split('; ').find(row => row.startsWith('lang='));
if (cookieLang) lang = cookieLang.split('=')[1];

export function L(key, ...args) {
    if (key in local) {
        if (args) {
            let out = local[key];
            for (let i in args) {
                out = out.replaceAll('{'+(+i+1)+'}', args[i]);
            }
            return out;
        } else {
            return local[key];
        }
    } else {
        return '{'+key+'}';
    }
}

export function changeLang(lang) {
    document.cookie = 'lang=' +lang + "; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
    document.location.reload(true);
}
