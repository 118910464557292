import {writable} from 'svelte/store';
export const userdata = writable(null);
export const gameStore = writable([]);
export const producerStore = writable([]);
export const bonusStore = writable(null);
export const lastURL = writable("/");
export const levelsStore = writable({});
export const siteSettings = writable({
    varLogin: [],
    mainPaymentMethods: []
});

export function fetchGames() {
    fetch("/server/getGames")
    .then(response => response.json())
    .then(data => {
        gameStore.set(data.games);
        producerStore.set(data.producers);
    });
}

export function fetchBonuses() {
    fetch("/server/profile/getBonuses")
    .then(response => response.json())
    .then(resp => bonusStore.set(resp));
}

fetch("/server/getLevels")
.then(response => response.json())
.then(resp => levelsStore.set(resp));

fetch("/server/getSettings")
.then(response => response.json())
.then(resp => siteSettings.set(resp));
