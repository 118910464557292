<svelte:head>
    <title>{title}</title>
</svelte:head>

<script>
import router from "page";
import LeftMenu from './components/LeftMenu.svelte';
import Main from './pages/Main.svelte';
import Game from './pages/Game.svelte';
import Profile from './pages/Profile.svelte';
import SaleDetail from './pages/SaleDetail.svelte';
import Modal from 'svelte-simple-modal';
import {userdata, fetchGames, fetchBonuses, gameStore, lastURL, siteSettings} from './js/stores.js';
import {query} from './js/scripts.js';
const isIPhone = !document.fullscreenEnabled && !document.webkitFullscreenEnabled;


function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
const searchParams = new URLSearchParams(window.location.search);
const cookies = document.cookie.split('; ');
for (const utm of utmParams) {
    if (searchParams.has(utm) && cookies.find(row => row.startsWith(utm+'='))==undefined) {
        setCookie(utm, searchParams.get(utm), 30);
    }
}
if (searchParams.has('subid')) {
    setCookie('subid', searchParams.get('subid'), 30);
}

let page, pgName, params;
let titles;
let title=document.title;
let gameTitles=[];

gameStore.subscribe(value => {
    for (let game of value) {
        gameTitles[game.uri] = game.title;
    }
});

siteSettings.subscribe(value => titles = value.titles);

async function getUserData() {
    let data = await query("/server/getUserData");
    if (data.email!=undefined) {
        userdata.set(data);
        fetchBonuses();
    }
    fetchGames();
}

if (cookies.find(row => row.startsWith('sid='))!=undefined)
    getUserData();
else
    fetchGames();

router('/', (ctx, next) => {params = ctx.params; next()}, () => { page = Main; pgName='main'});
router('/profile', (ctx, next) => {params = ctx.params; params.tab="balance"; next()}, () => {page = Profile; pgName='cabinet'});
router('/bonuses', (ctx, next) => {params = ctx.params; params.tab="bonuses"; next()}, () => {page = Profile; pgName='cabinet'});
router('/promotions', (ctx, next) => {params = ctx.params; params.panelPrefix = 'PROMOTIONS'; params.itemsPrefix='PROMO'; params.itemsCount=3; next()}, () => {page = SaleDetail; pgName='sale-detail'});
router('/vip_levels', (ctx, next) => {params = ctx.params; params.panelPrefix = 'VIP_MEMBERSHIP'; params.itemsPrefix='NEWS'; params.itemsCount=20; next()}, () => {page = SaleDetail; pgName='sale-detail'});
router('/:subpage', (ctx, next) => {params = ctx.params; next()}, () => { page = Main; pgName='main'});
router('/:subpage/:producer', (ctx, next) => {params = ctx.params; next()}, () => { page = Main; pgName='main'});
router('/:subpage/:producer/:game', (ctx, next) => {params = ctx.params; params.demo = false; next()}, () => {page = Game; pgName='game'});
router('/:subpage/:producer/:game/demo', (ctx, next) => {params = ctx.params; params.demo = true; next()}, () => {page = Game; pgName='game'});

router.start();

// Monkey patching history.pushState to change the window title on the fly.
(function(history){
    var pushState = history.pushState;
    history.pushState = function(state) {
        pushState.apply(history, arguments);
        let url = new URL(document.location);
        let titleUrl = url.pathname;
        let parts = url.pathname.split('/');
        if (parts.length>3) {
            if (titleUrl.endsWith('/demo')) titleUrl = '/game/demo';
            else titleUrl = '/game';
            title = titles[titleUrl].replace('{title}', gameTitles[parts[3]]);
        } else {
            if (titles!=undefined) title = titles[titleUrl]??titles['/'];
            lastURL.set(url.pathname);
        }
    };
})(window.history);

</script>

<main>
<Modal
  styleWindowWrap={{margin:0}}
  styleContent={{padding: "40px 6px 0 6px"}}
  styleBg={{background: 'rgba(0,0,0,0.75)'}}
  styleWindow={{backgroundColor:'transparent', width: "880px", height:"667px"}}
  closeButton={false}
>
    <div class="loto-million-page {pgName}-page">
        {#if !isIPhone || pgName!='game'}
        <LeftMenu />
        {/if}
        <svelte:component this={page} params={params}/>
    </div>
</Modal>
</main>
