<script>
import {L, lang, changeLang} from '../js/local.js';
import {userdata, bonusStore, fetchGames, siteSettings} from '../js/stores.js';
import { slide } from 'svelte/transition';
import page from "page";
import {query, loadSupport } from '../js/scripts.js';

let bonusCounter=0;
let userLogged = false;
let langs = [];
let menuItems;

function logout() {
    fetch("/server/logout");
    page("/");
    userdata.set(null);
    bonusStore.set(null);
    fetchGames();
    leftBarOn = false;
}

function rotateLang() {
    let idx = langs.indexOf(lang) + 1;
    if (langs.length <= idx) idx = 0;
    changeLang(langs[idx]);
}

userdata.subscribe(value => {
    userLogged = value != null;
    if (userLogged) menuItems = ['profile', 'cash', 'games', 'bonuses', 'vip_levels', 'promotions', 'support'];
    else menuItems = ['games', 'vip_levels', 'promotions', 'support'];
}
);
bonusStore.subscribe(value => {bonusCounter = value ? value.available.length + (value.cashback > 0) : 0});
siteSettings.subscribe(value => langs = value.langs);
let leftBarOn = false;
let langSwitcherOn = false;
let supportLoaded = false;
const langNames = {"uk": "Укр", "en": "Eng", "ru": "Рус"}
</script>

<div class="left-bar-buttons">
    <a href={null} class="burger-btn img-wrapper" class:is-on={leftBarOn} on:click={() => leftBarOn = !leftBarOn}>
        <img src="/images/menu-burger-icon.svg" alt="burger" class="svg-inline burger-icon">
        <img src="/images/burger-arrow-back-icon.svg" alt="burger-arrow-back" class="svg-inline burger-arrow-back">
    </a>
</div>

<div class="left-bar-menu" class:is-on={leftBarOn}>
    <div class="left-menu-wrapper">
        {#each menuItems as title}
        <a class="left-menu-item" href={null} on:click={async() => {
            switch (title) {
            case "games":
                page("/popular");
                let anchorElement = document.getElementsByClassName("main-filter-block")[0];
                if (anchorElement) window.scrollTo({top: anchorElement.offsetTop, behavior: 'smooth'});
                break;
            case "cash":
                page("/deposit");
                break;
            case "support":
                loadSupport(userLogged);
                break;
            default:
                page("/"+title);
                window.scrollTo({top:0});
            }
            leftBarOn = false;
        }}>
            <div class="img-wrapper">
                <img src="/images/left-menu/menu-icon-{title}.svg" alt="menu-icon">
                {#if title=='bonuses' && bonusCounter > 0}
                    <div class="circle">{bonusCounter}</div>
                {/if}
            </div>
            <p>{L(title.toUpperCase())}</p>
        </a>
        {/each}
    </div>
    <div class="land-switcher-wrapper">
        <a class="land-switcher-item" href={null} on:click={() => langSwitcherOn = !langSwitcherOn} class:is-on={langSwitcherOn}>
            <a href={null} on:click={() => {if (!leftBarOn) rotateLang()}}>{langNames[lang]}</a>
            <p>
                <span>{L('CHANGE_LANGUAGE')}</span>
                <span class="land-switcher-item-icon"></span>
            </p>
        </a>
        {#if langSwitcherOn}
        <div class="land-switcher-blocks" transition:slide="{{duration: 500}}">
            {#each langs as language}
            <a href={null} class="land-switcher-block" class:is-on={lang==language} on:click={() => {if (lang!=language) changeLang(language)}}>
                <p>{langNames[language]}</p>
            </a>
            {/each}
        </div>
        {/if}
    </div>
    {#if userLogged}
    <div class="left-menu-wrapper">
        <a class="left-menu-item" href={null} on:click={logout}>
            <div class="img-wrapper">
                <img src="/images/left-menu/menu-icon-10.svg" alt="menu-icon">
            </div>
            <p>{L('LOG_OUT')}</p>
        </a>
    </div>
    {/if}
</div>
<style>
  .circle {
    width: 16px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    background: #FED018;
    position: absolute;
    top: -10px;
    left: 16px;
    font-weight: bold;
}
</style>
