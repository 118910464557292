<script>
import { slide } from 'svelte/transition';
import GameCard from '../components/GameCard.svelte';
import MainPageSlider from '../components/MainPageSlider.svelte';
import HeaderContainer from '../components/HeaderContainer.svelte';
import Footer from '../components/Footer.svelte';
import WinnersBlock from '../components/WinnersBlock.svelte';
import PaymentForm from '../components/PaymentForm.svelte';
import LoginForm from '../components/LoginForm.svelte';
import { onMount, getContext } from 'svelte';
import {L} from '../js/local.js';
import {gameStore, producerStore, userdata, siteSettings} from '../js/stores.js';
import {query, loadSupport} from '../js/scripts.js';
import page from "page";
const { open } = getContext('simple-modal');

export let params;
let allGames = [];
let gamesPage = 0;
let games = [];
let producers = [];
let producerName;
let moreGamesOn = true;
let categories = ["popular", "new", "favorite", "slots", "minigames", "boardgames", "recent"];
let mobileFilterCategoriesOn = false;
let mobileFilterProducersOn = false;
let mainFilterBlock;
let userLogged;
let mainPaymentMethods;

gameStore.subscribe(value => allGames = value);
producerStore.subscribe(value => producers = value);
userdata.subscribe(value => userLogged = value!=null);
siteSettings.subscribe(value => mainPaymentMethods = value.mainPaymentMethods);


$: {
    if (params.subpage == undefined) {
        params.subpage = "popular";
    }
    else if (params.subpage == "deposit") {
        if (userLogged) {
            open(PaymentForm);
        } else {
            open(LoginForm, {tab: "login"});
        }
    }
    else if (params.subpage == "recoverPassword") {
        setTimeout(() => open(LoginForm, {tab: "newPassword"}), 0);
    }
}
$: {
    if (params.producer != undefined) {
        producers.forEach(p => {
            if (p.id==params.producer)
                producerName = p.name;
        });
    } else {
        producerName = "";
    }
}
$: {
    if (allGames && params.subpage) {
        gamesPage = 0;
        loadMoreGames();
    }
}

onMount(async() => {
    if (!userLogged) return;
    let data = await query("/server/getUserData");
    if (data.email!=undefined) {
        userdata.set(data);
    }
});

function handleTagFilterChange(event) {
    if (event.detail.checked) {
        tagFilters.push(event.detail.key);
    } else {
        const idx = tagFilters.indexOf(event.detail.key);
        tagFilters.splice(idx, 1);
    }
    gamesPage = 0;
    loadMoreGames();
}

function loadMoreGames() {
    gamesPage++;
    if (params.subpage=='popular') {
        games = allGames;
    } else if (params.subpage=='new') {
        games = [...allGames].sort((a, b) => a.id < b.id ? 1 : -1);
    } else if (params.subpage=='favorite') {
        games = allGames.filter(game => game.favorite);
    } else if (params.subpage=='slots') {
        games = allGames.filter(game => game.type=='slot' && (!params.producer || params.producer == game.producer));
    } else if (params.subpage=='minigames') {
        games = allGames.filter(game => game.type=='minigame');
    } else if (params.subpage=='boardgames') {
        games = allGames.filter(game => game.type=='boardgame');
    } else if (params.subpage=='recent') {
        games = allGames.filter(game => game.last_played!=undefined).sort((a,b) => a.last_played < b.last_played ? 1 : -1);
    }
    let gamesCnt = games.length;
    games = games.slice(0, gamesPage*24);
    moreGamesOn = games.length != gamesCnt;
}

function goToProvider(p) {
    page("/slots/" + p);
    let anchorElement = document.getElementsByClassName("main-filter-block")[0];
    if (anchorElement) window.scrollTo({top: anchorElement.offsetTop, behavior: 'smooth'});
}


</script>

<div class="page-content-group">

    <HeaderContainer pageName="main" />
    <MainPageSlider />
    
    <section class="loto-container main-filter-block" bind:this={mainFilterBlock}>
        <div class="loto-block">
            <div class="category-filter-panel">
                <div class="category-wrapper">
                    {#each categories as category}
                    <a href={null} class="category-item" on:click={()=>page("/"+(category!="popular"?category:""))} class:is-on={category==params.subpage}>
                        <div class="img-wrapper">
                            <img src="/images/game-group/game-icon-{category}.png" alt="category-icon">
                        </div>
                        <p>{L(category.toUpperCase())}</p>
                    </a>
                    {/each}
                </div>
            </div>
            <div class="tag-filter-panel" class:is-on={params.subpage=="slots"}>
                {#if params.subpage=="slots"}
                <div transition:slide="{{duration: 500}}" class="tag-filter-wrapper is-on">
                    {#each producers as p}
                    <a href={null} class="tag-filter-item" class:is-on={p.id==params.producer} class:is-grey={p.id=="other"} on:click={() => page("/slots/"+p.id)}>
                        <p class="tag-filter-item__label">{p.name}</p>
                        <p class="tag-filter-item__counter"><span>{p.cnt}</span></p>
                    </a>
                    {/each}
                </div>
                {/if}
            </div>
            <div class="mobile-filters">
                <a href={null} class="mobile-filter-selector" class:is-on={mobileFilterCategoriesOn} on:click={() => mobileFilterCategoriesOn = !mobileFilterCategoriesOn}>
                    <p class="mobile-filter-selector__value">{L(params.subpage.toUpperCase())}</p>
                    <p class="mobile-filter-selector__arrow"></p>
                </a>
                <a href={null} class="mobile-filter-selector" class:is-on={mobileFilterProducersOn} on:click={() => mobileFilterProducersOn = !mobileFilterProducersOn}>
                    <p class="mobile-filter-selector__value">{producerName ? producerName : L('PRODUCERS')}</p>
                    <p class="mobile-filter-selector__arrow"></p>
                </a>
            </div>
        </div>
    </section>

    <section class="loto-container main-games-block">
        <div class="loto-block">
            <div class="game-cards-wrapper">
            {#each games as game}
            <GameCard data={game} anchor={mainFilterBlock}/>
            {/each}
            </div>
            {#if moreGamesOn}
            <div class="btn-wrapper">
                <a href={null} class="btn btn-more-games" on:click={loadMoreGames}>{L('MORE_GAMES')}</a>
            </div>
            {/if}
        </div>
    </section>

    <WinnersBlock anchor={mainFilterBlock}/>

    <section class="loto-container main-deposit-block">
        <div class="loto-block">
            <div class="deposit-methods">
            {#each mainPaymentMethods as method}
                <div class="method-item">
                    <div class="img-wrapper">
                        <img src="/images/popup-form/deposit/{method}.png" alt="method-logo">
                    </div>
                </div>
            {/each}
            </div>
            <div class="deposit-description">
                <p class="deposit-title">{L('DEPOSIT_MONEY_TITLE')}</p>
                <p>{L('FOOTER_TEXT_ABOUT_SITE_1')}</p>
                <p>{L('FOOTER_TEXT_ABOUT_SITE_2')}</p>
                <a href={null} class="btn btn-deposit-now" on:click={()=>page("/deposit")}>{L('DEPOSIT_MONEY')}</a>
            </div>
        </div>
    </section>

    <section class="loto-container main-partners-block">
        <div class="loto-block">
            <div class="partners-wrapper">
                {#each producers.filter(x => x.id != 'erotic') as p}
                <div class="partner-item">
                    <a class="img-wrapper" href={null} on:click={()=>goToProvider(p.id)}>
                        <img src="/images/partners-logo/{p.id}.png" alt="{p.name}">
                    </a>
                </div>
                {/each}
            </div>
        </div>
    </section>

    <Footer />
</div>

<div class="mobile-filter-selector-items-block" class:is-on={mobileFilterCategoriesOn}>
    <a href={null} class="mobile-filter-selector-items-close" on:click={() => mobileFilterCategoriesOn=false}> </a>
    <div class="mobile-filter-selector-items">
        {#each categories as category}
        <a href={null} on:click={() => {
            mobileFilterCategoriesOn=false;
            page("/"+category);
        }}>{L(category.toUpperCase())}</a>
        {/each}
    </div>
</div>

<div class="mobile-filter-selector-items-block" class:is-on={mobileFilterProducersOn}>
    <a href={null} class="mobile-filter-selector-items-close" on:click={() => mobileFilterProducersOn=false}> </a>
    <div class="mobile-filter-selector-items">
        {#each producers as p}
        <a href={null} on:click={() => {
            mobileFilterProducersOn=false;
            page("/slots/"+p.id);
        }}>{p.name}<span class="item-counter">{p.cnt}</span></a>
        {/each}
    </div>
</div>
