<script>
import {L} from '../js/local.js';
import { getContext, onMount } from 'svelte';
const { close } = getContext('simple-modal');
import {userdata} from '../js/stores.js';
import {query } from '../js/scripts.js';

const rowsPerPage=10;

export let bonus;
export let bonusDeposit=0;

let tab='deposit';
let depositMethod;
let depositSum=100;
let depositError;
let withdrawMethod;
let withdrawSum=100;
let withdrawAccount;
let withdrawError;
let withdrawSuccess = false;
let cancelSuccess = false;
let history = [];
let historyPage = 0;
let formatter;
let allMethods = {deposit: [], withdraw: [], pagedDeposit:[], pagedWithdraw:[]};
let user;
let depositMin=100;
let depositMax=15000;
let withdrawMin=0;
let withdrawMax=0;
let balance=0;

userdata.subscribe(value => {
    user = value;
    if (value) formatter = Intl.NumberFormat(undefined, {style:"currency", currency:value.currency, minimumFractionDigits: 0});
});

onMount(async() => {
    let resp = await query("/server/payment/getMethods");
    if (resp.error==undefined) {
        allMethods = resp.methods;
        for (let i = 0; i < allMethods.deposit.length; i++) {
            if (allMethods.deposit[i].name.endsWith(' RUB'))
                allMethods.deposit[i].name = allMethods.deposit[i].name.slice(0, -4);
        }
        for (let i = 0; i < allMethods.withdraw.length; i++) {
            if (allMethods.withdraw[i].name.endsWith(' RUB'))
                allMethods.withdraw[i].name = allMethods.withdraw[i].name.slice(0, -4);
        }
        allMethods.pagedDeposit = [];
        for (let i = 0; i < allMethods.deposit.length; i += 4) {
            allMethods.pagedDeposit.push(allMethods.deposit.slice(i, i+4));
        }
        allMethods.pagedWithdraw = [];
        for (let i = 0; i < allMethods.withdraw.length; i += 4) {
            allMethods.pagedWithdraw.push(allMethods.withdraw.slice(i, i+4));
        }
    }
    resp = await query("/server/payment/getHistory");
    if (resp.error==undefined) {
        for (let entry of resp) {
            switch (entry.state) {
                case "new":
                case "processing":
                    entry.color = "processing"; break;
                case "cancelled":
                    entry.color = "error"; break;
                case "accepted":
                    entry.color = "complete"; break;
            }
        }
        history = resp;
        historyPage = 0;
    }
})


async function getDepositLimits(method) {
    depositMethod = method;
    depositSum = 100;
    depositError = '';
    let resp = await query("/server/payment/depositLimits?method="+method.id);
    if (resp.error==undefined) {
        depositMin = resp[0] / 100;
        depositMax = resp[1] / 100;
        depositSum = bonusDeposit || depositMin;
    }
}

async function deposit() {
    if (depositSum < depositMin || depositSum > depositMax) {
        depositError = 'ERROR_WRONG_AMOUNT';
    } else {
        depositError = '';
        let url = "/server/payment/deposit?method="+depositMethod.id+"&amount="+depositSum;
        if (bonus != undefined) url += "&bonus="+bonus;
        let resp = await query(url);
        if (resp.status == "success")
            location.href = resp.url;
    }
}

async function getWithdrawLimits(method) {
    withdrawMethod = method;
    withdrawSum = 100;
    withdrawAccount = '';
    withdrawError = '';
    let resp = await query("/server/payment/withdrawLimits?method="+method.id);
    if (resp.error==undefined) {
        withdrawMin = resp[0] / 100;
        withdrawMax = resp[1] / 100;
        balance = resp[2] / 100;
        withdrawSum = withdrawMin;
    }
}

async function withdraw() {
    if (withdrawSum < withdrawMin || withdrawSum > withdrawMax) {
        withdrawError = 'ERROR_WRONG_AMOUNT';
    } else {
        let resp = await query("/server/payment/withdraw?method="+withdrawMethod.id+"&amount="+withdrawSum+"&account="+withdrawAccount);
        withdrawError = resp.error ?? "";
        withdrawSuccess = resp.status == "success";
        if (withdrawSuccess) {
            user.balance = resp.balance;
            userdata.set(user);
            history.unshift({id: resp.id, method: withdrawMethod.name, amount: withdrawSum*100, date: Date.now()/1000, direction:"withdraw", state:"new", color:"processing"})
            history = history;
        }
    }
}

async function cancelWithdraw(id) {
    let resp = await query("/server/payment/cancelWithdraw?id="+id);
    if (resp.status=='success') {
        cancelSuccess = true;
        for (let i in history) {
            if (history[i].id==id) {
                history[i].state = "cancelled";
                history = history;
                break;
            }
        }
        user.balance = resp.balance;
        userdata.set(user);
    }
}
</script>

<div class="popup-form">
    <a href={null} class="close-btn" on:click={close}>
        <span class="icon"></span>
    </a>
    <div class="popup-form__switch-controls">
        <a href={null} class="popup-form__switch-control-item" class:is-on={tab=="deposit"} on:click={()=> {tab="deposit"; withdrawMethod=null;}}>
            <div class="btn"><span>{L('DEPOSIT_TAB_TITLE')}</span></div>
        </a>
        <a href={null} class="popup-form__switch-control-item" class:is-on={tab=="withdraw"} on:click={()=> {tab="withdraw"; depositMethod=null;}}>
            <div class="btn"><span>{L('WITHDRAW_TAB_TITLE')}</span></div>
        </a>
        <a href={null} class="popup-form__switch-control-item" class:is-on={tab=="history"} on:click={()=> {tab="history"; withdrawMethod=null; depositMethod=null;}}>
            <div class="btn"><span>{L('HISTORY_TAB_TITLE')}</span></div>
        </a>
    </div>
    <div class="popup-form__switch-container">
        <div class="popup-form__switch-item" class:is-on={tab=="deposit"}>
            {#if !depositMethod}
            <div class="form-container">
                <p class="form-title">{L('CHOOSE_DEPOSIT_METHOD')}</p>

                {#each allMethods.pagedDeposit as page}
                <div class="deposit-methods">
                    {#each page as method}
                    <a href={null} class="deposit-method-item" on:click={()=>getDepositLimits(method)}>
                        <div class="img-wrapper">
                            <img src="/images/popup-form/deposit/{method.id}.png" alt="deposit-img">
                        </div>
                        <p>{method.name}</p>
                    </a>
                    {/each}
                </div>
                {/each}
            </div>
            {:else}
            <div class="form-container form-deposit-detail">
                <div class="form-line form-line__operation-type">
                    <div class="img-wrapper">
                        <img class="oparetion-type__preview" src="/images/popup-form/deposit/{depositMethod.id}.png" alt="payment system">
                    </div>
                    <div class="oparetion-type__group">
                        <p class="form-title">{depositMethod.name}</p>
                        <div class="form-group">
                            <a class="btn btn-submit" href={null} on:click={() => depositMethod=null}>{L('CHANGE')}</a>
                        </div>
                    </div>
                </div>
                <div class="form-line form-line-processed">
                    <div class="form-group sum-value">
                        <input type="text" class="form-control" placeholder="{L('AMOUNT')}" bind:value={depositSum}>
                        <p class="subtitle">
                            {L('MINIMUM_DEPOSIT')}: {depositMin} ₽<br>
                            {L('MAXIMUM_DEPOSIT')}: {depositMax} ₽
                        </p>
                    </div>
                    <div class="form-group precessed-btn">
                        <a class="btn" href={null} on:click={deposit}>{L('CONTINUE')}</a>
                    </div>
                </div>

                {#if depositError}
                    <p class="basefont" style="color:red">{L(depositError)}</p>
                {/if}

                <div class="placeholder-money">
                {#each [100,500,1000,5000,10000,15000] as value}
                    <a href={null} class="placeholder-money__item" on:click={() => depositSum=value}>{value}</a>
                {/each}
                </div>
            </div>
            {/if}
        </div>
        <div class="popup-form__switch-item" class:is-on={tab=="withdraw"}>
        {#if !withdrawSuccess}
            {#if !withdrawMethod}
            <div class="form-container">

                <p class="form-title">{L('CHOOSE_WITHDRAW_METHOD')}</p>

                {#each allMethods.pagedWithdraw as page}
                <div class="deposit-methods">
                    {#each page as method}
                    <a href={null} class="deposit-method-item" on:click={()=>getWithdrawLimits(method)}>
                        <div class="img-wrapper">
                            <img src="/images/popup-form/withdraw/{method.id}.png" alt="deposit-img">
                        </div>
                        <p>{method.name}</p>
                    </a>
                    {/each}
                </div>
                {/each}
            </div>
            {:else}
            <div class="form-container form-deposit-detail">
                <div class="form-line form-line__operation-type">
                    <div class="img-wrapper">
                        <img class="oparetion-type__preview" src="/images/popup-form/withdraw/{withdrawMethod.id}.png" alt="payment system">
                    </div>
                    <div class="oparetion-type__group">
                        <p class="form-title">{withdrawMethod.name}</p>
                        <div class="form-group">
                            <a class="btn btn-submit" href={null} on:click={() => withdrawMethod=null}>{L('CHANGE')}</a>
                        </div>
                    </div>
                </div>
                <div class="form-line form-line-processed">
                    <div class="form-group sum-value">
                        <input type="text" class="form-control" placeholder="{L('AMOUNT')}" bind:value={withdrawSum}>
                        <p class="subtitle">
                            {L('MINIMUM_AMOUNT')}: {formatter.format(withdrawMin)}<br>
                            {L('MAXIMUM_AMOUNT')}: {formatter.format(withdrawMax)}<br>
                            {L('AVAILABLE_TO_WITHDRAW')}: {formatter.format(balance)}
                        </p>
                    </div>
                </div>
                <div class="form-line form-line-processed">
                    <div class="form-group sum-value">
                        <input type="text" class="form-control" placeholder={L(withdrawMethod.id.includes('sbp')?'PHONE':'ACCOUNT')} bind:value={withdrawAccount}>
                    </div>
                    <div class="form-group precessed-btn">
                        <a class="btn" href={null} on:click={withdraw}>{L('CONTINUE')}</a>
                    </div>
                </div>
                
                {#if withdrawError}
                    <p class="basefont" style="color:red">
                    {#if withdrawError != 'NO_MONEY'}
                        {L(withdrawError)}
                    {:else}
                        {L(withdrawError, formatter.format(Math.min(balance, withdrawMax)))}
                    {/if}
                    </p>
                {/if}
                <div class="placeholder-money">
                {#each [100,500,1000,5000,10000,15000] as value}
                    <a href={null} class="placeholder-money__item" on:click={() => withdrawSum=value}>{value}</a>
                {/each}
                </div>
            </div>
            {/if}
        {:else}
            <p class="basefont">{L('WITHDRAW_REQUEST_IS_ACCEPTED')}</p>
            <a class="btn btn-processed" style="margin-top: 20px" href={null} on:click={() => {withdrawMethod=null; withdrawSuccess=false}}>{L('BACK')}</a>
        {/if}
        </div>
        <div class="popup-form__switch-item" class:is-on={tab=="history"}>
        {#if !cancelSuccess}
            <div class="operation-history-table">
                <table>
                    <thead>
                        <tr>
                            <td>{L('DATE')}</td>
                            <td>{L('AMOUNT')}</td>
                            <td>{L('TRANSACTION')}</td>
                            <td>{L('PAYMENT_METHOD')}</td>
                            <td>{L('STATUS')}</td>
                        </tr>
                    </thead>
                    <tbody>
                    {#each history.slice(historyPage*rowsPerPage, (historyPage+1)*rowsPerPage) as entry}
                        <tr>
                            <td>{new Date(entry.date*1000).toLocaleDateString()}</td>
                            <td>{formatter.format(entry.amount/100)}</td>
                            <td>{L(entry.direction.toUpperCase())}</td>
                            <td>{entry.method}</td>
                            <td class="status-{entry.color}">
                            {L("PAYMENT_STATE_"+entry.state.toUpperCase())}
                            {#if entry.state=='new' && entry.direction=='withdraw'}
                                - <a href={null} style="color:#B01338" on:click={()=>cancelWithdraw(entry.id)}>{L('CANCEL')}</a>
                            {/if}
                            </td>
                        </tr>
                    {/each}
                    </tbody>
                </table>
                <div class="operation-history-table__mobile-view">
                    {#each history.slice(historyPage*rowsPerPage, (historyPage+1)*rowsPerPage) as entry}
                    <div class="operation-history-table__mobile-item">
                        <div class="operation-history-table__mobile-line">
                            <p class="operation-history-table__mobile-name">{L('DATE')}</p>
                            <p class="operation-history-table__mobile-value">{new Date(entry.date*1000).toLocaleDateString()}</p>
                        </div>
                        <div class="operation-history-table__mobile-line">
                            <p class="operation-history-table__mobile-name">{L('AMOUNT')}</p>
                            <p class="operation-history-table__mobile-value">{formatter.format(entry.amount/100)}</p>
                        </div>
                        <div class="operation-history-table__mobile-line">
                            <p class="operation-history-table__mobile-name">{L('TRANSACTION')}</p>
                            <p class="operation-history-table__mobile-value">{L(entry.direction.toUpperCase())}</p>
                        </div>
                        <div class="operation-history-table__mobile-line">
                            <p class="operation-history-table__mobile-name">{L('PAYMENT_METHOD')}</p>
                            <p class="operation-history-table__mobile-value">{entry.method}</p>
                        </div>
                        <div class="operation-history-table__mobile-line">
                            <p class="operation-history-table__mobile-name">{L('STATUS')}</p>
                            <p class="operation-history-table__mobile-value status-{entry.color}">
                            {L("PAYMENT_STATE_"+entry.state.toUpperCase())}
                            {#if entry.state=='new' && entry.direction=='withdraw'}
                                - <a href={null} style="color:#B01338" on:click={()=>cancelWithdraw(entry.id)}>{L('CANCEL')}</a>
                            {/if}
                            </p>
                        </div>
                    </div>
                    {/each}
                </div>
                {#if history.length > rowsPerPage}
                {#each Array(Math.ceil(history.length / rowsPerPage)) as _, i}
                    {#if historyPage!=i}
                    <a href={null} style="color:#8D7392" on:click={() => historyPage=i}>{i+1}</a>&nbsp;&nbsp;
                    {:else}
                    <b style="color:white">{i+1}</b>&nbsp;&nbsp;
                    {/if}
                {/each}
                {/if}
            </div>
        {:else}
            <p class="basefont">{L('WITHDRAW_REQUEST_IS_CANCELLED')}</p>
            <a class="btn btn-processed" style="margin-top: 20px" href={null} on:click={() => {cancelSuccess=false}}>{L('BACK')}</a>
        {/if}
        </div>
    </div>
</div>
