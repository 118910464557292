<script>
import {gameStore} from '../js/stores.js';
import page from "page";
import { getContext } from 'svelte';
const { close } = getContext('simple-modal');
let searchString = "";
let allGames = [];

gameStore.subscribe(value => allGames = value);

$: searchResults = searchString.length < 2 ?
    allGames.toSorted((a, b) => b.id - a.id).slice(0, 5) :
    allGames.filter(game => game.title.toLowerCase().includes(searchString.toLowerCase()));

</script>

<div class="seach-form is-on">
    <div class="seach-form__header">
        <div class="seach-form__icon"></div>
        <input class="seach-form__field" type="text" bind:value={searchString}>
        <a href={null} class="seach-form__icon seach-form__icon--btn-clear" on:click={close}> </a>
    </div>
    <div class="seach-form__result" class:is-on={searchResults.length}>
        <p class="seach-form__result-title">Результаты поиска</p>
        <div class="seach-form__result-items" style="max-height:654px; overflow:auto">
            {#each searchResults as game}
            <a href={null} class="seach-form__result-item" on:click={() => {close(); page(["", game.type+"s", game.producer, game.uri].join('/'))}}>
                <div class="seach-form__result-img" style="background-image: url('/images/game-card/{game.outer_uri.toLowerCase()}.jpg')"></div>
                <div class="seach-form__result-name">{game.title}</div>
            </a>
            {/each}
        </div>
    </div>
</div>
