<script>
export let isOn, category;
import { onMount } from 'svelte';
import {gameStore} from '../js/stores.js';
import page from "page";
let sliderItem, sliderInstance, buttonNext, buttonPrev, games;

gameStore.subscribe((value) => {
    if (category=='popular') games = value.slice(0, 24);
    else if (category=='favorite') games = value.filter(game => game.favorite);
    else games = [];
});

$: {
   if (isOn) sliderInstance.update();
}

onMount(() => {
    sliderInstance = new Swiper(sliderItem, {
        speed: 500,
        spaceBetween: 12,

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        slidesPerView: 6,
        slidesPerGroup : 1,
        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev,
        },

        simulateTouch: false,
        allowTouchMove: false,

        breakpoints: {
            1530: {slidesPerView: 6},
            1450: {slidesPerView: 5},
            1350: {slidesPerView: 4},
            820:  {slidesPerView: 3},
            600:  {slidesPerView: 2},
            1:    {slidesPerView: 1}
        }
    });
});
</script>

<section class="game-selector-bg" class:is-on={isOn}>
    <div class="game-slide-container">
        <div class="swiper-container popular-game-slider" bind:this={sliderItem}>
            <div class="swiper-wrapper">
                {#each games as game}
                <div class="swiper-slide">
                    <div class="slide-content">
                        <a href={null} on:click={() => {isOn=false; page(["", game.type+"s", game.producer, game.uri].join('/'))}} class="game-card" style="background-image: url('/images/game-card/{game.outer_uri.toLowerCase()}.jpg');"> </a>
                    </div>
                </div>
                {/each}
            </div>
        </div>
        <div class="swiper-button-prev" bind:this={buttonPrev}></div>
        <div class="swiper-button-next" bind:this={buttonNext}></div>
    </div>
</section>
