<script>
import { onMount } from 'svelte';
import {L} from '../js/local.js';
import {gameStore} from '../js/stores.js';
import GameCard from './GameCard.svelte';
import {query } from '../js/scripts.js';

export let anchor;

let allGames = {}
let allWinners = [];
let winnersGroup = 1;
let winnersPage = 1;

gameStore.subscribe(value => {
    for (let game of value) {
        allGames[game.id] = game;
    }
    if (value.length > 0 && allWinners.length==0) {
        query("/server/getWinners")
        .then(data => {
            if (data.error==undefined) {
                for (let i=0; i<3; i++) {
                    allWinners[i] = data[i].map(winner => {
                        Object.assign(winner, allGames[winner.gameID]);
                        delete winner.gameID;
                        return winner;
                    });
                }
            }
        });
    }
});

$: winners = allWinners[winnersGroup] ? allWinners[winnersGroup].slice(0, 10*winnersPage) : [];
$: moreWinners = allWinners[winnersGroup] ? winners.length != allWinners[winnersGroup].length : false;

</script>

<section class="loto-container main-winnders-block">
    <div class="loto-block">
        <div class="border-line"></div>

        <p class="winners-title">{L('WINNERS')}</p>
        <div class="switch-container">
            <div class="switch-controls">
                {#each Array(3) as _, i}
                <a href={null} class="switch-control" class:is-on={winnersGroup==i} on:click={() => {winnersGroup=i; winnersPage=1}}>{L('WINNERS_GROUP_'+i)}</a>
                {/each}
            </div>
            <div class="game-cards-wrapper">
            {#each winners as winner}
                <GameCard data={winner} anchor={anchor}/>
            {/each}
            </div>
        </div>
        {#if moreWinners}
        <div class="btn-wrapper">
            <a href={null} on:click={() => {winnersPage++}} class="btn btn-more-winners">{L('MORE_WINNERS')}</a>
        </div>
        {/if}
    </div>
</section>
