<script>
import PopularGameSlider from '../components/PopularGameSlider.svelte';
import HeaderContainer from '../components/HeaderContainer.svelte';
import { onMount, onDestroy } from 'svelte';
import {isMobile, query} from '../js/scripts.js';
import page from "page";
import {L} from '../js/local.js';
import {gameStore, lastURL} from '../js/stores.js';
import { slide } from 'svelte/transition';

export let params;
let gameData = {};
let showPopular = false;
let showFavorite = false;
let winnersVisible = false;
let gameWindow;
let interval;
let winners = [];
let winnersHead = 0;
let allGames = [];
let returnURL = '';
const isIPhone = !document.fullscreenEnabled && !document.webkitFullscreenEnabled;

const handleWindowResize = () => {
   if (window.matchMedia('(orientation: landscape)').matches) {
     document.body.style.height = `${Math.min(screen.availHeight, screen.availWidth)}px`;
   } else {
     document.body.style.height = '100%';
   }
};

gameStore.subscribe(value => {
    for (let game of value) {
        allGames[game.id] = game;
    }
    if (!isMobile && value.length > 0 && winners.length==0) {
        getMoreWinners();
        winnersVisible = true;
    }
});
lastURL.subscribe(value => returnURL = value);

onMount(() => {
    if (isMobile) goFullscreen();
});

onDestroy(() => {
    if (interval) clearInterval(interval);
    if (isIPhone) window.removeEventListener('resize', handleWindowResize);

});


function goFullscreen() {
    if (document.fullscreenEnabled) gameWindow.requestFullscreen();
    else if (document.webkitFullscreenEnabled) gameWindow.webkitRequestFullscreen();
    else window.addEventListener('resize', handleWindowResize);
}

async function getMoreWinners() {
    let resp = await query("/server/getCurrentWinners?head="+winnersHead);
    if (resp.error==undefined) {
        winners = resp.winners.concat(winners).slice(0, 6);
        winnersHead = resp.head;
    }
}

$: {
    let url = "/server/getGameData?game="+params.game;
    if (params.demo) url+= "&demo=true";
    query(url).then(data => {
        if (data.error==undefined) {
           if (!isMobile && data.producer=='lm') data.url += "&desktop";
           gameData = data;
        }
    });
}

$: {
    if (winnersVisible) {
        interval = setInterval(getMoreWinners, 15000);
    } else {
        clearInterval(interval);
        interval = null;
    }
}

function toggleFavorite() {
    gameData.favorite = !gameData.favorite;
    fetch("/server/setFavorite?val="+gameData.favorite+"&gameId="+gameData.id);
}

</script>

{#if isIPhone}
<div bind:this={gameWindow} style="width:100%; height: 100vh; position: fixed; left: 0; top: 0">
<iframe title="Game" src="{gameData.url}" width="100%" height="100%" frameborder="0"></iframe>
</div>
{:else}
<div class="page-content-group">
    <PopularGameSlider bind:isOn={showPopular} category="popular" />
    <PopularGameSlider bind:isOn={showFavorite} category="favorite" />
    <HeaderContainer pageName="game" bind:showFavorite={showFavorite} bind:showPopular={showPopular}/>

    <section class="loto-container game-container">
        <div class="loto-block game-block">
            <div class="game-controls">
                <a href={null} class="game-control game-control_favorite" class:is-on={gameData.favorite} on:click={toggleFavorite}>
                    <div class="img-wrapper">
                        <img src="/images/game-card/like-icon.svg" alt="control-icon" class="general">
                        <img src="/images/game-card/like-icon-hover.svg" alt="control-icon" class="hover">
                        <img src="/images/game-card/like-icon-active.svg" alt="control-icon" class="active">
                    </div>
                </a>
                <a href={null} class="game-control game-control_fullscreen" on:click={goFullscreen}>
                    <div class="img-wrapper">
                        <img src="/images/game-page/game-fullscreen-icon.svg" alt="control-icon" class="svg-inline">
                    </div>
                </a>
                <a href={null} class="game-control game-control_close" on:click={() => page(returnURL)}>
                    <div class="img-wrapper">
                        <img src="/images/game-page/game-close-icon.svg" alt="control-icon" class="svg-inline">
                    </div>
                </a>
            </div>
            <div class="game-window" bind:this={gameWindow}>
                <iframe title="Game" src="{gameData.url}" frameborder="0"></iframe>
            </div>
        </div>
        {#if winnersVisible}
        <div class="loto-block winners-block">
            <div class="winners-list-wrapper">
                {#each winners as w (w)}
                    {@const game = allGames[w.gameID]}
                    <div class="winners-list-item" transition:slide|local>
                        <a href={null} class="img-wrapper" on:click={()=>page(["", game.type+"s", game.producer, game.uri].join('/'))}>
                            <img src="/images/game-card/{game.outer_uri.toLowerCase()}.jpg" alt="winner-icon">
                        </a>
                        <div class="winners-list-item_content">
                            <p class="winners-list-item_game-name">{game.title.length>18 ? game.title.slice(0,15) + "..." : game.title}</p>
                            <p class="winners-list-item_user-name">{w.name}</p>
                            <p class="winners-list-item_win-value">
                                <span class="val">{Math.round(w.win/100)}</span>
                                <span class="currency">RUB</span>
                            </p>
                        </div>
                    </div>
                {/each}
            </div>
            <div class="winners-controls">
                <a href={null} on:click={() => winnersVisible=false} class="btn">{L('HIDE')}</a>
                <a href={null} on:click={() => page("/")} class="btn">{L('QUIT')}</a>
            </div>
        </div>
        {/if}
    </section>
</div>
{/if}
