<script>
import HeaderContainer from '../components/HeaderContainer.svelte';
import PaymentForm from '../components/PaymentForm.svelte';
import Message from '../components/Message.svelte';
import {L} from '../js/local.js';
import { onMount, onDestroy, getContext } from 'svelte';
const { open } = getContext('simple-modal');
import {bonusStore, levelsStore, fetchBonuses} from '../js/stores.js';
import {query} from '../js/scripts.js';
import page from "page";

export let params;
let startYear = new Date().getFullYear() - 18;
let data = {networks: []};
let bonuses = {active: [], available: []};
let wager = {current:"", starting: ""};
let savePersonalDataAnswer;
let balances = {};
let currencySymbol;
let interval;
let formatMoney;
let pointsToExchange = 0;
let bonusUnsubscribe;
let confirmationEmailSent = false;
let inputError = {};
let birthdayDisabled = true;
let genderDisabled = true;
let levels = {};

$: tab = params.tab;

const openMessage = (msg) =>
    open(Message, {msg: msg});

levelsStore.subscribe(value => levels = value);

onMount(async() => {
    let profileData = await query("/server/profile/getProfileData");
    if (profileData.currency!=undefined && profileData.error==undefined) {
        data = profileData;
        pointsToExchange = data.points;
        if (data.bday==undefined) birthdayDisabled = false;
        if (data.gender==' ') genderDisabled = false;
        formatMoney = Intl.NumberFormat(undefined, {style:"currency", currency:data.currency}).format;
        balances = {
            total: formatMoney((data.balance+data.balanceBonus)/100),
            common: formatMoney(data.balance/100),
            bonus: formatMoney(data.balanceBonus/100),
            cashback: formatMoney(data.cashback/100)
        }
        data.needForLevel = formatMoney(data.needForLevel);
        currencySymbol = balances.total.charAt(balances.total.length-1);
        if (currencySymbol >= '0' && currencySymbol <= '9') {
            currencySymbol = balances.total.charAt(0);
        }
        let wagerData = await query("/server/profile/getWager");
        if (wagerData.error==undefined) {
            wager.raw = wagerData.current;
            wager.current = formatMoney(wagerData.current/100);
            wager.starting = formatMoney(wagerData.starting/100);
        }
        bonusUnsubscribe = bonusStore.subscribe(value => {
            if (value) {
                for (let bonus of value.active) {
                    if (!isNaN(bonus.percent))
                        bonus.percent = formatMoney(bonus.percent);
                }
                for (let bonus of value.available) {
                    if (!isNaN(bonus.percent))
                        bonus.percent = formatMoney(bonus.percent);
                }
                bonuses = value;
                tickBonusTimer();
            }
        });
    } else {
        page("/");
        return;
    }
    interval = setInterval(tickBonusTimer, 1000);
    fetchBonuses();
});

onDestroy(() => {
    if (interval) clearInterval(interval);
    if (bonusUnsubscribe != undefined) bonusUnsubscribe();
} );

function tickBonusTimer() {
    let t = Math.floor(Date.now() / 1000);
    let filterBonuses = false;
    for (let bonus of bonuses.active) {
        let diff = Math.floor(bonus.liveEnd) - t;
        if (diff < 0) {
            bonus.expired = true;
            filterBonuses = true;
        } else {
            bonus.dayLeft = Math.floor(diff / 86400);
            bonus.hrLeft = Math.floor(diff % 86400 / 3600);
            bonus.minLeft = Math.floor(diff % 3600 / 60);
            bonus.secLeft = diff % 60;
        }
    }
    for (let bonus of bonuses.available) {
        let diff = Math.floor(bonus.activateEnd) - t;
        if (diff <= 0) {
            bonus.expired = true;
            filterBonuses = true;
        } else {
            bonus.dayLeft = Math.floor(diff / 86400);
            bonus.hrLeft = Math.floor(diff % 86400 / 3600);
            bonus.minLeft = Math.floor(diff % 3600 / 60);
            bonus.secLeft = diff % 60;
        }
    }
    let diff = Math.floor(bonuses.cashbackExpire) - t;
    if (diff <= 0) {
        bonuses.cashback = 0;
    } else {
        bonuses.cashbackDayLeft = Math.floor(diff / 86400);
        bonuses.cashbackHrLeft = Math.floor(diff % 86400 / 3600);
        bonuses.cashbackMinLeft = Math.floor(diff % 3600 / 60);
        bonuses.cashbackSecLeft = diff % 60;
    }
    if (filterBonuses)
        bonusStore.set({active: bonuses.active.filter(x => !x.expired), available: bonuses.available.filter(x => !x.expired), cashback: bonuses.cashback});
    else
        bonuses=bonuses;
}

async function savePersonalData() {
    let bday = +data['bday'];
    let bmonth = +data["bmonth"];
    let byear = +data["byear"];
    inputError = {
        day: isNaN(bday) || bday < 1 || bday > 31,
        month: isNaN(bmonth) || bmonth < 1 || bmonth > 12,
        year: isNaN(byear) || byear < 1900 || byear > new Date().getFullYear()
    }
    if (Object.values(inputError).some(x=>x)) return;
    let fd = new FormData();
    for (let key of ["name", "nickname", "email", "bday", "bmonth", "byear", "gender"])
        fd.append(key, data[key]);
    let resp = await query("/server/profile/savePersonalData", {method: "POST", body: fd});
    if (resp.error==undefined) {
        savePersonalDataAnswer = resp;
        birthdayDisabled = true;
        if (data.gender!=' ') genderDisabled = true;
    }
}

async function activateBonus(min_deposit, id) {
    if (bonuses.active.length > 0 || wager.raw > 0) {
        openMessage('ANOTHER_BONUS_ACTIVATED_ALREADY');
        return;
    }
    if (!min_deposit) {
        let response = await query("/server/profile/activateBonus?id="+id);
        if (response.error==undefined) {
            let resp = await query("/server/profile/getBonuses");
            if (resp.error==undefined) bonusStore.set(resp);
            let wagerData = await query("/server/profile/getWager");
            if (wagerData.error==undefined) {
                wager.raw = wagerData.current;
                wager.current = formatMoney(wagerData.current/100);
                wager.starting = formatMoney(wagerData.starting/100);
            }
            let profileData = await query("/server/profile/getProfileData");
            if (profileData.error==undefined) {
                balances = {
                    total: formatMoney((profileData.balance+profileData.balanceBonus)/100),
                    common: formatMoney(profileData.balance/100),
                    bonus: formatMoney(profileData.balanceBonus/100),
                    cashback: formatMoney(profileData.cashback/100)
                }
            }
        }
    } else {
        open(PaymentForm, {bonus: id, bonusDeposit: min_deposit});
    }
}

async function exchangePoints(points) {
    if (bonuses.active.length > 0 || wager.raw > 0) {
        openMessage('ANOTHER_BONUS_ACTIVATED_ALREADY');
        return;
    }
    pointsToExchange = 0;
    let resp = await query("/server/profile/exchangePoints?points="+points);
    if (resp.error==undefined) {
        data.points = resp.points;
        data.balanceBonus = resp.balanceBonus;
        balances = {
            total: formatMoney((data.balance+data.balanceBonus)/100),
            common: formatMoney(data.balance/100),
            bonus: formatMoney(data.balanceBonus/100),
            cashback: balances.cashback
        }
        wager.starting = balances.bonus;
        wager.current = formatMoney(resp.wager/100);
    }
}

async function sendConfirmationEmail() {
    let response = await fetch("/server/sendConfirmationEmail");
    if (response.ok)
        confirmationEmailSent = true;
}

</script>

<div class="page-content-group">
    <HeaderContainer pageName="cabinet"/>

    <section class="loto-container cabinet-block">
        <div class="loto-block">
            <div class="cabinet-switcher mb-large">
                <a href={null} class="cabinet-switcher__item" class:is-on={tab=="balance"} on:click={()=> tab="balance"}>{L('BALANCE')}</a>
                <a href={null} class="cabinet-switcher__item" class:is-on={tab=="personalData"} on:click={()=> tab="personalData"}>{L('PERSONAL_DATA')}</a>
                <a href={null} class="cabinet-switcher__item" class:is-on={tab=="bonuses"} on:click={()=> tab="bonuses"}>{L('MY_BONUSES')}</a>
            </div>
            <div class="cabinet-switcher__content" class:is-on={tab=="balance"}>
                <p class="cabinet__title">{L('BALANCE')}</p>
                <p class="cabinet__desc mb-large">{L('CONTROL_BALANCE_AND_EXCHANGE_POINTS')}</p>
                <div class="cabinet__line mb-medium">
                    <div class="cabinet__info-field">
                        <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__general-balance.svg');"></div>
                        <p class="cabinet__info-field-label">{L('TOTAL_BALANCE')}</p>
                        <p class="cabinet__info-field-value cabinet__info-field-value--general">{balances.total}</p>
                        <a class="cabinet__info-field-add" href={null} on:click={() => open(PaymentForm)}> </a>
                    </div>
                    <div class="cabinet__info-field">
                        <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__main-balance.svg');"></div>
                        <p class="cabinet__info-field-label">{L('COMMON_BALANCE')}</p>
                        <p class="cabinet__info-field-value">{balances.common}</p>
                    </div>
                </div>
                <div class="cabinet__line mb-large">
                    <div class="cabinet__info-field">
                        <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__bonus-balance.svg');"></div>
                        <p class="cabinet__info-field-label">{L('BONUS_BALANCE')}</p>
                        <p class="cabinet__info-field-value">{balances.bonus}</p>
                    </div>
                    <div class="cabinet__info-field">
                        <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__cashback-balance.svg');"></div>
                        <p class="cabinet__info-field-label">{L('CASHBACK')}</p>
                        <p class="cabinet__info-field-value">{balances.cashback}</p>
                    </div>
                </div>
                <p class="cabinet__title mb-large">{L('POINTS')}</p>
                <div class="cabinet__line cabinet__line--align-start">
                    <div class="cabinet__line-group">
                        <div class="cabinet__line mb-medium">
                            <div class="cabinet__info-field">
                                <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__total-score.svg');"></div>
                                <p class="cabinet__info-field-label">{L('TOTAL')}</p>
                                <p class="cabinet__info-field-value">{data.points}</p>
                            </div>
                            <div class="cabinet__info-field">
                                <p class="cabinet__info-field-label">{L('EXCHANGE_RATE')}</p>
                                <p class="cabinet__info-field-value">100 : {data.exchangeRate}</p>
                            </div>
                        </div>
                        <div class="cabinet__large-game">
                            <div class="cabinet__large-game-preview">
                                <img width="50" height="50" src="/images/levels/{levels[data.level]?levels[data.level].pic:'11.png'}" alt="level">
                                <div class="progress-popup" style="top:70%; left: -25px">
                                    <p>{L('YOU_NEED_X_BET_FOR_NEXT_LEVEL', data.needForLevel)}</p>
                                </div>
                            </div>
                            <div class="cabinet__large-game-content">
                                <p class="cabinet__large-game-text">
                                    <span>{L('LEVEL_'+data.level)}</span>
                                    {#if !data.lastLevel}
                                    <span>{L('LEVEL_'+(data.level+1))}</span>
                                    {/if}
                                </p>
                                <div class="cabinet__large-game-progress">
                                    <div class="cabinet__large-game-progress-line">
                                        <div class="cabinet__large-game-progress-line-filler" style="width: {data.levelPercent}%"></div>
                                    </div>
                                    <div class="cabinet__large-game-progress-val">{data.levelPercent}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cabinet__line-group">
                        <p class="cabinet__subtitle mb-medium">{L('EXCHANGE_POINTS')}</p>
                        <div class="cabinet__exchange">
                            <div class="cabinet__exchange-group">
                                <p class="cabinet__exchange-label">{L('POINTS')}</p>
                                <input class="cabinet__exchange-score-field" type="text" bind:value={pointsToExchange} on:keyup={() => pointsToExchange = Math.min(parseInt(pointsToExchange), data.points) || 0 }>
                            </div>
                            <button class="cabinet__exchange-btn cabinet__exchange-btn--desktop" on:click={() => exchangePoints(pointsToExchange)}></button>
                            <div class="cabinet__exchange-group">
                                <p class="cabinet__exchange-label">{L('YOU_WILL_GET')}</p>
                                <div class="cabinet__exchange-score-field-res">
                                    <p>{pointsToExchange * data.exchangeRate/100}</p>
                                    <p>{currencySymbol}</p>
                                </div>
                            </div>
                            <button class="btn btn-sign-in cabinet__exchange-btn cabinet__exchange-btn--mobile" on:click={() => exchangePoints(pointsToExchange)}>{L("EXCHANGE")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cabinet-switcher__content" class:is-on={tab=="personalData"}>
                <div class="cabinet__line mb-large">
                    <div class="cabinet__group">
                        <p class="cabinet__title">{L('PERSONAL_DATA')}</p>
                        <p class="cabinet__desc">{L('PERSONAL_DATA_MANAGEMENT')}</p>
                    </div>
                    <div class="game-progress-bar">
                        <div class="game-progress-bar-logo">
                            <img width="50" height="50" src="/images/levels/{levels[data.level]?levels[data.level].pic:'11.png'}" alt="level">
                        </div>
                        <div class="progress-bar">
                            <div class="progress-bar__status" style="width: {data.levelPercent}%"></div>
                            <p class="progress-bar__title">{data.levelPercent}%</p>
                        </div>
                        <div class="game-progress-bar-rank-name">{L('STATUS')}: {L('LEVEL_'+data.level)}</div>
                        <div class="progress-popup">
                            <p>{L('YOU_NEED_X_BET_FOR_NEXT_LEVEL', data.needForLevel)}</p>
                        </div>
                    </div>
                </div>
                <div class="cabinet__personal-container-group">
                    <div class="cabinet__personal-container">
                        <div class="cabinet__personal-group">
                            <p class="cabinet__personal-label">{L('NAME')}</p>
                            <input class="cabinet__personal-field" type="text" placeholder="{L('ENTER_NAME')}" bind:value={data.name}>
                        </div>
                        <div class="cabinet__personal-group">
                            <p class="cabinet__personal-label">{L('NICKNAME')}</p>
                            <input class="cabinet__personal-field" type="text" placeholder="{L('ENTER_NICKNAME')}" bind:value={data.nickname}>
                        </div>
                    </div>
                    <div class="cabinet__personal-container">
                        <div class="cabinet__personal-group">
                            <p class="cabinet__personal-label cabinet__personal-label--email">
                                <span>{L('EMAIL')}</span>
                                {#if !data.emailConfirm}
                                <span class="cabinet__personal-label-not-confirm">{L('NOT_CONFIRMED')}</span>
                                {/if}
                            </p>
                            <input class="cabinet__personal-field" type="email" placeholder="test@mail.ru" bind:value={data.email}>
                            {#if !data.emailConfirm && confirmationEmailSent}
                            <p class="cabinet__personal-label" style="text-align: right">{L('CONFIRMATION_EMAIL_SENT')}</p>
                            {/if}
                        </div>
                        {#if !data.emailConfirm && !confirmationEmailSent}
                            <button class="cabinet__personal-btn-email-confirm" on:click={sendConfirmationEmail}>{L('CONFIRM')}</button>
                        {/if}
                        <div class="other-login cabinet__personal-group">
                            <p class="cabinet__personal-label">{L('LINK_SOCIAL_NETWORKS')}</p>
                            <div id="uLogin" class="other-login__links-wrapper" data-ulogin="display=buttons;fields=email,verified_email,first_name,last_name;redirect_uri=https%3A%2F%2F{window.location.host}%2Fserver%2Fulogin;">
                                {#each ["yandex", "google", "mailru", "odnoklassniki", "vkontakte"] as sn}
                                <div class="other-login__link" style={data.networks.includes(sn)?'opacity:1':''}>
                                    <span class="img-wrapper"><img src="/images/popup-form/{sn}-icon.svg" alt="icon" data-uloginbutton={data.networks.includes(sn)?null:sn}></span>
                                </div>
                                {/each}
                            </div>
                        </div>    
                    </div>
                    <div class="cabinet__personal-container">
                        <div class="cabinet__personal-group">
                            <p class="cabinet__personal-label">{L('DATE_OF_BIRTH')}</p>
                            {#each ["day", "month", "year"] as field}
                            <input class="cabinet__personal-field" type="text" style="width:32%;{inputError[field]?'border-color:red':''}" placeholder="{L(field.toUpperCase())}" bind:value={data["b"+field]} disabled={birthdayDisabled}>
                            {/each}
                        </div>
                        <div class="cabinet__personal-group">
                            <p class="cabinet__personal-label">{L('GENDER')}</p>
                            <div class="cabinet__personal-sex-group">
                                <label class="cabinet__personal-sex-item">
                                    <input type="radio" name="sex" bind:group={data.gender} value="m" disabled={genderDisabled}>
                                    <span class="cabinet__personal-sex-item-btn">
                                        <span class="cabinet__personal-sex-item-btn-icon" style="background-image: url('/images/cabinet/icon__male.svg');"></span>
                                        <span>{L('MALE')}</span>
                                    </span>
                                </label>
                                <label class="cabinet__personal-sex-item">
                                    <input type="radio" name="sex" bind:group={data.gender} value="f" disabled={genderDisabled}>
                                    <span class="cabinet__personal-sex-item-btn">
                                        <span class="cabinet__personal-sex-item-btn-icon" style="background-image: url('/images/cabinet/icon__female.svg');"></span>
                                        <span>{L('FEMALE')}</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {#if savePersonalDataAnswer}
                <p class="cabinet__desc">{L(savePersonalDataAnswer)}</p>
                {/if}
                <button class="btn btn-sign-in btn-save-setting" on:click={savePersonalData}>{L('SAVE')}</button>
            </div>
            <div class="cabinet-switcher__content" class:is-on={tab=="bonuses"}>
                <p class="cabinet__title mb-large">{L('MY_BONUSES')}</p>
                <div class="cabinet__line mb-large">
                    <div class="cabinet__info-field">
                        <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__bonus-balance.svg');"></div>
                        <p class="cabinet__info-field-label">{L('WAGER_STARTING')}</p>
                        <p class="cabinet__info-field-value">{wager.starting}</p>
                    </div>
                    <div class="cabinet__info-field">
                        <div class="cabinet__info-field-icon" style="background-image: url('/images/cabinet/icon__game-count.svg');"></div>
                        <p class="cabinet__info-field-label">{L('WAGER_CURRENT')}</p>
                        <p class="cabinet__info-field-value">{wager.current}</p>
                    </div>
                </div>
                <p class="cabinet__subtitle mb-large">{L('ACTIVE')}</p>
                {#if bonuses.active.length}
                <div class="cabinet__bonus-list">
                    {#each bonuses.active as bonus}
                    <div class="cabinet__bonus-list-item">
                        <img src="/images/bonus/{bonus.type!='cashback' ? bonus.pic : 'cashback'+(levels[data.level]?levels[data.level].cashback:5) + '.png'}" alt="Bonus">
                        <div class="cabinet__bonus-list-item-content">
                            <p class="cabinet__bonus-list-item-name"><b>{bonus.type!='cashback' ? bonus.name : L('BONUS_CASHBACK') + ' ' + (levels[data.level]?levels[data.level].cashback:5) + '%'}</b></p>
                            {#if bonus.type=="nondep"}
                            <p class="cabinet__bonus-list-item-value">{bonus.percent}</p>
                            {/if}
                            <div class="cabinet__bonus-list-item-line">
                                <p>{L('BONUS_EXPIRES_IN')}</p>
                                <p class="cabinet__bonus-list-item-line-timer">{#if bonus.dayLeft}<span>{bonus.dayLeft}</span>д : {/if}<span>{bonus.hrLeft}</span>ч : <span>{bonus.minLeft}</span>м {#if !bonus.dayLeft}: <span>{bonus.secLeft}</span>с{/if}</p>
                            </div>
                        </div>
                    </div>
                    {/each}
                </div>
                {:else}
                <div class="cabinet__bonus-plug mb-large">
                    <img src="/images/cabinet/bonus-thumb.png" alt="bonus-img">
                    <p>{L('YOU_HAVE_NO_ACTIVE_BONUSES')}</p>
                </div>
                {/if}
                <p class="cabinet__subtitle mb-large">{L('AVAILABLE')}</p>
                {#if bonuses.available.length || bonuses.cashback > 0}
                <div class="cabinet__bonus-list">
                    {#each bonuses.available as bonus}
                    <div class="cabinet__bonus-list-item">
                        <img src="/images/bonus/{bonus.pic}" alt="Bonus">
                        <div class="cabinet__bonus-list-item-content">
                            <p class="cabinet__bonus-list-item-name"><b>{bonus.name}</b></p>
                            {#if bonus.type=="nondep"}
                            <p class="cabinet__bonus-list-item-value">{bonus.percent}</p>
                            {:else if bonus.type=="freespin"}
                            <p class="cabinet__bonus-list-item-value">{bonus.max_bonus} {L('FS')}</p>
                            {/if}
                            <p class="cabinet__bonus-list-item-name">{@html bonus.description}</p>
                            <div class="cabinet__bonus-list-item-line">
                                <p>{L('BONUS_EXPIRES_IN')}</p>
                                <p class="cabinet__bonus-list-item-line-timer">{#if bonus.dayLeft}<span>{bonus.dayLeft}</span>д : {/if}<span>{bonus.hrLeft}</span>ч : <span>{bonus.minLeft}</span>м {#if !bonus.dayLeft}: <span>{bonus.secLeft}</span>с{/if}</p>
                            </div>
                            <button class="btn btn-sign-in" on:click={()=>activateBonus(bonus.min_deposit, bonus.id)}>{L('ACTIVATE_BONUS')}</button>
                        </div>
                    </div>
                    {/each}
                    {#if bonuses.cashback > 0}
                        <div class="cabinet__bonus-list-item">
                            <img src="/images/bonus/cashback{levels[data.level]?levels[data.level].cashback:5}.png" alt="Cashback">
                            <div class="cabinet__bonus-list-item-content">
                                <p class="cabinet__bonus-list-item-name"><b>{L('BONUS_CASHBACK')} {levels[data.level]?levels[data.level].cashback:5}%</b></p>
                                <p class="cabinet__bonus-list-item-value">{formatMoney(bonuses.cashback/100)}</p>
                                <p class="cabinet__bonus-list-item-name">{@html L('CASHBACK_DESCRIPTION_'+(levels[data.level]?levels[data.level].cashback:5))}</p>
                                <div class="cabinet__bonus-list-item-line">
                                    <p>{L('BONUS_EXPIRES_IN')}</p>
                                    <p class="cabinet__bonus-list-item-line-timer">{#if bonuses.cashbackDayLeft}<span>{bonuses.cashbackDayLeft}</span>д : {/if}<span>{bonuses.cashbackHrLeft}</span>ч : <span>{bonuses.cashbackMinLeft}</span>м {#if !bonuses.cashbackDayLeft}: <span>{bonuses.cashbackSecLeft}</span>с{/if}</p>
                                </div>
                                <button class="btn btn-sign-in" on:click={()=>activateBonus(false, 'cashback')}>{L('ACTIVATE_BONUS')}</button>
                            </div>
                        </div>
                    {/if}
                </div>
                {:else}
                <div class="cabinet__bonus-plug mb-large">
                    <img src="/images/cabinet/bonus-thumb.png" alt="bonus-img">
                    <p>{L('YOU_HAVE_NO_AVAILABLE_BONUSES')}</p>
                </div>
                {/if}
            </div>
        </div>
    </section>

</div>