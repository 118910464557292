<script>
import {L} from '../js/local.js';
import {userdata, fetchGames, fetchBonuses, siteSettings} from '../js/stores.js';
import { getContext } from 'svelte';
import {query } from '../js/scripts.js';
const { close } = getContext('simple-modal');
export let tab;
let loginError='';
let registerError='';
let otherLinksContainer = {};
let agreementChecked=true;
let currency='';
let forgotPasswordEmail, recoverPasswordError, disableResetPassword, savePasswordError;
let email, code;
let recoverEmail = "";
let varLogin;

siteSettings.subscribe(value => varLogin = value.varLogin);

async function submitLoginForm(e) {
    let resp = await query("/server/login", {method: "POST", body: new FormData(e.target)});
    if ('error' in resp) {
        loginError = resp.error;
    } else {
        userdata.set(resp);
        fetchGames();
        fetchBonuses();
        close();
    }
}

async function submitRegisterForm(e) {
    let resp = await query("/server/register", {method: "POST", body: new FormData(e.target)});
    if ('error' in resp) {
        registerError = resp.error;
    } else {
        userdata.set(resp);
        fetchGames();
        fetchBonuses();
        close();
    }
}

async function getDefaultCurrency() {
    let resp = await query("/server/getDefaultCurrency");
    if (resp.error==undefined)
        currency = resp;
}

async function recoverPasswordEmail(e) {
    disableResetPassword = true;
    let resp = await query("/server/recoverPasswordEmail", {method: "POST", body: new FormData(e.target)});
    recoverPasswordError = resp.error ?? 'ok';
    disableResetPassword = false;
}

async function newPassword(e) {
    let resp = await query("/server/recoverPassword", {method: "POST", body: new FormData(e.target)});
    savePasswordError = resp.error ?? 'ok';
}


$: {
    if (varLogin.includes("ulogin") && otherLinksContainer[tab]) {
        otherLinksContainer[tab].insertBefore(document.getElementById('uLogin'), otherLinksContainer[tab].lastChild);
    }
}
$: {
    if (tab=="register" && currency=="") getDefaultCurrency();
}
$: {
    if (tab=='newPassword') {
        const urlParams = new URLSearchParams(window.location.search);
        email = urlParams.get('email');
        code = urlParams.get('code');
    }
}
</script>

<div class="popup-form">
    <a href={null} class="close-btn" on:click={close}>
        <span class="icon"></span>
    </a>
    <div class="popup-form__switch-controls">
        <div class="popup-form__switch-control-item" class:is-on={tab=="register"}>
            <a href={null} on:click={()=> tab="register"} class="btn"><span>{L('SIGN_UP')}</span></a>
        </div>
        <div class="popup-form__switch-control-item" class:is-on={tab=="login"}>
            <a href={null} on:click={() => tab="login"} class="btn"><span>{L('LOG_IN')}</span></a>
        </div>
    </div>
    <div class="popup-form__switch-container">
        <div class="popup-form__switch-item" class:is-on={tab=="register"}>
            <form class="form-container" on:submit|preventDefault={submitRegisterForm}>
                <p class="form-title">{L('SIGN_UP')}</p>

                {#if varLogin.includes("ulogin")}
                <div class="other-login" bind:this={otherLinksContainer.register}>
                    <div id="uLogin" class="other-login__links-wrapper" data-ulogin="display=buttons;fields=email,verified_email,first_name,last_name;redirect_uri=https%3A%2F%2F{window.location.host}%2Fserver%2Fulogin;">
                        {#each ["yandex", "google", "mailru", "odnoklassniki", "vkontakte"] as sn}
                        <div class="other-login__link">
                            <span class="img-wrapper"><img src="/images/popup-form/{sn}-icon.svg" alt="icon" data-uloginbutton={sn}></span>
                        </div>
                        {/each}
                    </div>
                    <p class="other-login__label">{L('OR_USE_YOUR_ACCOUNT')}</p>
                </div>
                {/if}

                <div class="form-group">
                    <input type="email" name="email" class="form-control" value="" placeholder={L('ENTER_EMAIL')}>
                </div>
                <div class="form-group">
                    <input type="password" name="password" class="form-control" value="" placeholder={L('ENTER_PASSWORD')}>
                </div>
                {#if registerError}<div class="other-login" style="color: red">{L(registerError)}</div>{/if}
                <div class="form-group">
                    <input type="submit" class="btn btn-submit" value="{L('SIGN_UP')}" disabled={!agreementChecked}>
                </div>
                <div class="form-group agreement">
                    <label class="checkbox-classic checkbox-block">
                        <input class="checkbox-block__original" type="checkbox" name="checkbox-name" bind:checked={agreementChecked}>
                        <span class="checkbox-block__custom"></span>
                        <span class="checkbox-block__label">{L("IM_OLDER_THAN_18_AND_ACCEPT")}&nbsp;<a href={null}>{L("TERMS_AND_CONDITIONS")}</a></span>
                    </label>
                </div>
            </form>
        </div>
        <div class="popup-form__switch-item" class:is-on={tab=="login"}>
            <form class="form-container" on:submit|preventDefault={submitLoginForm}>
                <p class="form-title">{L('LOG_IN')}</p>

                {#if varLogin.includes("ulogin")}
                <div class="other-login" bind:this={otherLinksContainer.login}>
                    <p class="other-login__label">{L('OR_USE_YOUR_ACCOUNT')}</p>
                </div>
                {/if}

                <div class="form-group">
                    <input type="text" name="email" class="form-control" value="" placeholder={L('ENTER_EMAIL')}>
                </div>
                <div class="form-group">
                    <input type="password" name="password" class="form-control" value="" placeholder={L('ENTER_PASSWORD')}>
                </div>
                {#if loginError}<div class="other-login" style="color: red">{L(loginError)}</div>{/if}
                <div class="form-group">
                    <input type="submit" class="btn btn-submit" value={L('LOG_IN')}>
                </div>
                <div class="form-group forgot-password">
                    <a href={null} on:click={() => tab="forgotPassword"}>{L('FORGOT_YOUR_PASSWORD')}</a>
                </div>
            </form>
        </div>
        <div class="popup-form__switch-item" class:is-on={tab=="forgotPassword"}>
            <form class="form-container" on:submit|preventDefault={recoverPasswordEmail}>
                <p class="form-title">{L('PASSWORD_RECOVERY')}</p>
                {#if recoverPasswordError=="ok"}
                    <div class="other-login"><p class="other-login__label">{L('RECOVERY_EMAIL_SENT', recoverEmail)}</p></div>
                    <br><br>
                    <div class="other-login">
                        <p class="other-login__label">{L('NO_RECOVERY_EMAIL_FOR_TOO_LONG')}</p>
                        <p class="other-login__label">
                            <a href={null} on:click={() => recoverPasswordError=undefined} style="color:dodgerblue">{L('RESEND_EMAIL_LINK_TEXT')}</a>
                            {L('RESEND_EMAIL_OTHER_TEXT')}
                        </p>
                    </div>
                {:else}
                    <div class="other-login"><p class="other-login__label">{L('FORGOT_PASSWORD_ENTER_EMAIL')}</p></div>
                    <div class="form-group">
                        <input type="text" name="email" class="form-control" bind:value={recoverEmail} placeholder={L('ENTER_EMAIL')}>
                    </div>
                    <div class="form-group">
                        <input type="submit" class="btn btn-submit" value={L('RESET_PASSWORD')} disabled={disableResetPassword}>
                    </div>
                    {#if recoverPasswordError && recoverPasswordError != "ok"}
                        <div class="other-login"><p class="other-login__label" style="color:red">{L(recoverPasswordError)}</p></div>
                    {/if}
                {/if}
            </form>
        </div>
        <div class="popup-form__switch-item" class:is-on={tab=="newPassword"}>
            <form class="form-container" on:submit|preventDefault={newPassword}>
                <input type="hidden" name="email" value={email}>
                <input type="hidden" name="code" value={code}>
                <p class="form-title">{L('ENTER_NEW_PASSWORD')}</p>
                <div class="form-group">
                    <input name="password" type="password" class="form-control" placeholder={L('ENTER_NEW_PASSWORD')}>
                </div>
                <div class="form-group">
                    <input name="confirm" type="password" class="form-control" placeholder={L('CONFIRM_NEW_PASSWORD')}>
                </div>
                <div class="form-group">
                    <input type="submit" class="btn btn-submit" value={L('SAVE')}>
                </div>
                {#if savePasswordError}
                    {#if savePasswordError == "ok"}
                    <div class="other-login" style="color:white">{L('NEW_PASSWORD_SAVED')}</div>
                    {:else}
                    <div class="other-login" style="color:red">{L(savePasswordError)}</div>
                    {/if}
                {/if}
            </form>
        </div>
    </div>
</div>
